import * as Backend from 'queries/BackendRequest';

interface Prefs {
  projectId: string;
  loggedIn: boolean;
}

interface Projects {
  projectId: string;
  slug: string;
  roleId: number;
}

export interface InProject {
  isSigned: boolean;
}

const inProject = (response: Projects[], projectId: string) => {
  try {
    let found = false;
    response.forEach((el) => {
      if (el.projectId === projectId || el.slug === projectId) {
        found = true;
      }
    });
    return found;
  } catch (e) {
    return false;
  }
};

export const getInProject = async ({ projectId }: Prefs): Promise<InProject> => {
  const resp = await Backend.request<Projects[]>('get', '/user/project', {});
  return {
    isSigned: inProject(resp.data.data, projectId),
  };
};

export const userInProject = ({ projectId, loggedIn }: Prefs) => ({
  queryKey: ['userInProject', projectId, loggedIn],
  queryFn: () => getInProject({ projectId, loggedIn }),
  enabled: !!projectId && loggedIn,
});
