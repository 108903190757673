import * as Backend from '/queries/BackendRequest';

const isExistingSlug = (slug: string) => ({
  queryKey: ['slugExists', slug],
  queryFn: () => fetchSlug(slug),
  enabled: !!slug,
  gcTime: Infinity,
  staleTime: 1000 * 60 * 60 * 24,
});

const fetchSlug = async (slug: string): Promise<boolean> => {
  try {
    await Backend.request('GET', `/content-public/${slug}/exists`);
    return true;
  } catch (_) {
    return false;
  }
};

export default isExistingSlug;
